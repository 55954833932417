import { StyleSheet } from "aphrodite";
import QUERIES from "../../../styles/queries";
import COLORS from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts";

const styles = StyleSheet.create({
  root: {
    [QUERIES.mobile]: {
      padding: 10,
    },
  },
  content: {
    maxWidth: 600,
    margin: "0 auto",
    width: "100%",
    minHeight: "calc(100vh - 318px)",
  },
  inventHead: {
    color: COLORS.jjdGreyish,
    fontWeight: 600,
    fontSize: 24,
    margin: "16px 16px 26px",
  },
  wrapper: {
    transition: "200ms",
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    flex: "0 0 100%",
  },
  inventInput: {
    width: "100%",
    height: 57,
    margin: 0,
    padding: 24,
    fontSize: 18,
    fontWeight: "normal",
    background: COLORS.white,
    color: "#2c2c2c",
    boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid transparent",
    borderRadius: 48,
  },
  inventAddBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    background: COLORS.jjdRed,
    border: 0,
    color: COLORS.white,
    borderRadius: 48,
    height: 57,
    padding: "0 49px",
    fontSize: 18,
    fontWeight: 700,
    textTransform: "capitalize",
    cursor: "pointer",
  },
  quantitycontent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(44, 44, 44, 0.5)",
  },
  inputQuantityInput: {
    width: "100%",
    height: 57,
    margin: "18px 0 0",
    padding: 24,
    fontSize: 18,
    fontWeight: "normal",
    background: COLORS.white,
    color: "#2c2c2c",
    boxShadow: "0 6px 14px 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid transparent",
    borderRadius: 48,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  inventCont: {
    borderRadius: 48,
    maxWidth: 340,
    margin: "80px auto 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontFamily: FONTS.Content,
    fontSize: 18,
  },
  crossBtn: {
    cursor: "pointer",
    width: 30,
    height: 30,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: COLORS.jjdGreyish,
    color: COLORS.white,
  },
});

export default styles;
