import React from "react";
import InventoryForm from "../containers/bookingStep6/InventoryForm";
import Layout from "../containers/gatsby/layout";

const VanSizePage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <InventoryForm />
  </Layout>
);

export default VanSizePage;
