import _ from "lodash";
import React, { Component } from "react";
import BookingSteppers from "../../../components/booking/BookingHeader/BookingHeader";
import connect from "react-redux/es/connect/connect";
import { inInventoryAdd, onInventoryRemove } from "./InventoryFormActions";
import InventoryFormView from "./InventoryFormView";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { navigate } from "gatsby";
import { css } from "aphrodite";
import styles from "./InventoryForm.styles";

class InventoryFormController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingEventsFired: false,
      inventoryInputValue: "",
      loading: false,
    };
  }

  goNext = () => {
    ReactGA.event({
      category: "Web booking",
      action: "Add instruction",
      label: "Intruction",
    });
    ReactPixel.track("Lead");
    this.setState(
      {
        loading: true,
      },
      () => {
        setTimeout(() => {
          navigate("/instructions");
        }, 800);
      }
    );
  };

  onInventoryInputChange = (e) => {
    this.setState({
      inventoryInputValue: e.target.value,
    });
  };

  onInventoryAdd = () => {
    const { inventoryInputValue } = this.state;
    if (!_.isEmpty(inventoryInputValue)) {
      const { inventoryData } = this.props;
      const indexOfExistingInventory = _.findIndex(inventoryData, {
        title: inventoryInputValue,
      });

      if (indexOfExistingInventory !== -1) {
        // yes it exists
        this.props.inInventoryAdd(
          inventoryInputValue.toLowerCase(),
          inventoryData[indexOfExistingInventory].quantity + 1
        );
      } else {
        this.props.inInventoryAdd(inventoryInputValue.toLowerCase(), 1);
      }

      this.setState({
        inventoryInputValue: "",
      });
    }
  };

  onInventoryInputKeydown = (e) => {
    if (e.key === "Enter") {
      this.onInventoryAdd();
    }
  };

  render() {
    return (
      <>
        <div className={css(styles.wrapper)}>
          <BookingSteppers
            title={"Select a van size"}
            backUrl={"/choose-van-size"}
            disabled={typeof this.props.selectedVanIndex !== "number"}
          />

          <InventoryFormView
            {...this.props}
            goNext={this.goNext}
            onInventoryInputChange={this.onInventoryInputChange}
            onInventoryAdd={this.onInventoryAdd}
            inventoryInputValue={this.state.inventoryInputValue}
            onInventoryInputKeydown={this.onInventoryInputKeydown}
            loading={this.state.loading}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedVanIndex: state.bookingGeneral.vanSizeIndex,
    bookingPricesCustomChoices: state.bookingPricesCustomChoices,
    bookingDistance: state.bookingDistance,
    userDetails: state.userDetails,
    inventoryData: state.bookingGeneral.inventory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inInventoryAdd: (title, quantity) =>
      dispatch(inInventoryAdd(title, quantity)),
    onInventoryRemove: (index) => dispatch(onInventoryRemove(index)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryFormController);
