import React from "react";
import styles from "./InventoryForm.styles";
import { css } from "aphrodite";
import ExcellentReviews from "../../../components/booking/ExcellentReviews/ExcellentReviews";
import NumericInput from "../../../components/booking/NumericInput";
import BookingContinueButton from "../../../components/booking/BookingContinueButton/BookingContinueButton";

const InventoryFormView = (props) => (
  <div>
    <div className={css(styles.root)}>
      <div className={css(styles.content)}>
        <p className={css(styles.inventHead)}>Your inventory</p>
        <div className={css(styles.inputContainer)}>
          <input
            type="text"
            placeholder="Add items"
            value={props.inventoryInputValue}
            onChange={props.onInventoryInputChange}
            onKeyDown={props.onInventoryInputKeydown}
            className={css(styles.inventInput)}
          />

          <button
            className={css(styles.inventAddBtn)}
            onClick={props.onInventoryAdd}
          >
            add
        </button>
        </div>
        {props.inventoryData.map((item, index) => {
          return (
            <div className={css(styles.inputQuantityInput)}>
              <div className={css(styles.quantitycontent)}>
                <span>{item.title}</span>
              </div>

              <NumericInput
                value={item.quantity}
                onChange={(e) =>
                  props.inInventoryAdd(item.title.toLowerCase(), e.target.value)
                }
              />
              <span
                className={css(styles.crossBtn)}
                onClick={() => {
                  props.onInventoryRemove(index);
                }}
              >
                x
            </span>
            </div>
          );
        })}

        <BookingContinueButton
          className={css(styles.inventCont)}
          {...props}
          loading={props.loading}
        />
      </div>


    </div>
    <ExcellentReviews />
  </div>
);

export default InventoryFormView;
