// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./NumericInputStyles";
import Icon from "@material-ui/core/Icon";

export default function NumericInputView(props) {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <div className={`${css(styles.itemValueWrap)}`}>
        <span
          className={`${css(styles.minusButton)}`}
          onClick={props.handleClickDecrement}
        >
          -{/* <Icon className="fa fa-plus-circle" /> */}
        </span>

        <input
          type="text"
          className={`${css(styles.itemValueInput)}`}
          value={props.value}
          onChange={props.onChange}
        />

        <span
          className={`${css(styles.plusButton)}`}
          onClick={props.handleClickIncrement}
        >
          +{/* <Icon className="fa fa-plus-circle" /> */}
        </span>
      </div>
    </div>
  );
}
