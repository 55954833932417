// @flow
import { StyleSheet } from "aphrodite";
import COLORS from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts";
import QUERIES from '../../../styles/queries';

export default StyleSheet.create({
  itemValueWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    maxWidth: 150,
    height: 75,
    [QUERIES.lessThan800]: {
      // margin: '0 auto',
    },
    [QUERIES.greaterThan520]: {
      maxWidth: "39%",
    }
  },
  labelStyle: {
    fontSize: "14px",
    color: "black",
    marginTop: 15,
    marginBottom: 10,
    fontWeight: "600",
  },
  itemValueInput: {
    width: "calc(80% - 50px)",
    border: "none",
    padding: "10px",
    height: "43px",
    textAlign: "center",
    ":focus": {
      outline: "none",
    },
  },
  minusButton: {
    cursor: "pointer",
    width: 38,
    height: 38,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: COLORS.jjdRed,
    color: COLORS.white,
    fontSize: 24,
  },
  plusButton: {
    cursor: "pointer",
    width: 38,
    height: 38,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: COLORS.jjdRed,
    color: COLORS.white,
    fontSize: 24,
  },
  itemImgIcon: {
    width: "12px",
  },
});
